<template>
    <div class="page single" tabindex="0" ref="scrollTop">
        <div class="single__wrap">
            <div class="single__title">
                <router-link :to="{name:routeArchive}">
                    <icon-arrow-back/>
                    Get to know us
                </router-link>
            </div>
        </div>

        <transition name="slide-in" mode="out-in" @after-leave="scrollToElement">
            <router-view v-if="show" :key="$route.params.page"></router-view>
        </transition>

        <div class="single__footer">
            <transition name="slide-in" mode="out-in">
                <router-view v-if="show" name="nav" :key="$route.params.page"></router-view>
            </transition>
        </div>

    </div>
</template>

<script>
import {find as _find} from "lodash";
import c from "../router/constants"
import store from "../store";
import requestMixin from "../mixins/requestMixin";
// import BaseOverlay from "../components/baseOverlay";
import overlayMixin from "../mixins/overlayMixin";

export default {
    name: "PageSingle",
    mixins: [overlayMixin, requestMixin],
    data() {
        return {
            shown: false,
        }
    },
    computed: {
        from() {
            return this.$route.params.from ? this.$route.params.from.path : false
        },
        post() {
            return store.post;
        },
        show() {
            return this.transitionEntered;
        },
        routeArchive() {
            return c.ABOUT
        },
        routeName() {
            return c.POST
        }
    },
    mounted() {
        if (!store.posts || !store.posts.length) {
            this.loadPosts()
        }

        this.scrollToElement()
    },
    watch: {
        'post.widget': {
            handler(newWidget) {
                if (newWidget) {
                    this.updateStoreWidget(newWidget)
                }
            },
            immediate: true
        }
    },
    methods: {
        updateStorePost(slug) {
            if (store.posts && store.posts.length) {
                store.post = _find(store.posts, {slug});
            }
        },

        updateStoreWidget(id) {
            console.log('Try ', id)
            if (!id)
                return;
            if (store.widget && store.widget.id === id) {
                return;
            }

            if (store.widgets && store.widgets.length) {
                store.widget = _find(store.widgets, {id});
            }

            if (!store.widget) {
                this.loadWidget(id)
            }
        },

        scrollToElement() {
            const el = this.$refs.scrollTop;

            if (el) {
                // Use el.scrollIntoView() to instantly scroll to the element
                el.scrollIntoView({behavior: 'smooth'});
                // el.scrollTop = 0;
                //{behavior: 'smooth'}
            }
        }

    },
    beforeRouteUpdate(to, from, next) {
        this.updateStorePost(to.params.page)
        this.updateStoreWidget(store.post.widget)
        to.params.menuSlug = from.params.menuSlug
        to.params.pause = from.params.pause

        next()
    },

}
</script>

<style scoped>
.section {
    flex-wrap: nowrap;
}
</style>